import {
	Button,
	Checkbox,
	Chip,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	Paper,
	Stack,
} from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	getAllCategories,
	setIsCreateCategory,
	setNewCategory,
	createCategory,
} from '../../redux/slices/categorySlice';
import {
	setTitle,
	setContent,
	setVideoAddress,
	createPost,
	clearSuccess,
} from '../../redux/slices/postSlice';
import './create-post.scss';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Section from '../../components/Section';
import TransferList from '../../components/TransferList';

const CreatePost = () => {
	const { activeUser } = useSelector((state) => state.user);
	const { isCreateCategory, newCategory } = useSelector(
		(state) => state.category
	);
	const { title, categories, content, videoAddress, selectedPost, success } =
		useSelector((state) => state.post);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleChange = (input, value) => {
		const actionMap = {
			title: setTitle,
			isCreateCategory: setIsCreateCategory,
			category: setNewCategory,
			video: setVideoAddress,
			content: setContent,
		};

		const action = actionMap[input];

		action && dispatch(action(value));
	};

	const handleCreateCategory = () => {
		const data = {
			category: newCategory,
		};
		dispatch(createCategory(data));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const newPost = {
			title,
			categories,
			content,
			...(videoAddress && { videoAddress }),
			author: activeUser?._id,
		};

		dispatch(createPost(newPost));
	};

	const handleLoadCategories = useCallback(() => {
		dispatch(getAllCategories());
	}, [dispatch]);

	const handleSuccess = useCallback(() => {
		if (success) navigate(`/admin/blog/post/${selectedPost?._id}`);
		dispatch(clearSuccess());
	}, [navigate, dispatch, success, selectedPost]);

	useEffect(() => {
		handleLoadCategories();
	}, [handleLoadCategories]);

	useEffect(() => {
		handleSuccess();
	}, [handleSuccess]);

	return (
		<Section id='create-post' maxWidth='xl'>
			<Paper elevation={5}>
				<form className='post-form' onSubmit={handleSubmit}>
					<Divider>
						<Chip label='Title' size='small' className='divider-chip' />
					</Divider>
					<div className='create-form-group title'>
						<FormControl>
							<input
								type='text'
								placeholder='Title'
								className='create-input'
								value={title}
								onChange={(e) => handleChange('title', e.target.value)}
								autoFocus
							/>
						</FormControl>
					</div>
					<Divider>
						<Chip label='Categories' size='small' className='divider-chip' />
					</Divider>
					<div className='create-form-group transfer'>
						<FormControl>
							<TransferList />
						</FormControl>
						<Stack direction='row' gap={2} alignItems='center'>
							<FormControlLabel
								control={
									<Checkbox
										checked={isCreateCategory}
										onChange={(e) =>
											handleChange('isCreateCategory', e.target.checked)
										}
									/>
								}
								label='New Category'
							/>
							{isCreateCategory && (
								<Stack direction='row' alignItems='center'>
									<input
										type='text'
										placeholder='New Category'
										className='create-input'
										value={newCategory}
										onChange={(e) => handleChange('category', e.target.value)}
									/>
									<IconButton
										disabled={!newCategory}
										className='add-category-btn'
										onClick={handleCreateCategory}
									>
										<AddCircleIcon />
									</IconButton>
								</Stack>
							)}
						</Stack>
					</div>
					<Divider>
						<Chip label='Video' size='small' className='divider-chip' />
					</Divider>
					{videoAddress && (
						<iframe
							title='Vlog Post'
							src={videoAddress}
							frameBorder='0'
							controls='0'
							allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
							allowFullScreen
							className='create-img'
						/>
					)}
					<div className='create-form-group video-url'>
						<FormControl>
							<input
								type='text'
								placeholder='Video URL'
								className='create-input'
								value={videoAddress}
								onChange={(e) => handleChange('video', e.target.value)}
							/>
						</FormControl>
					</div>
					<Divider>
						<Chip label='Content' size='small' className='divider-chip' />
					</Divider>
					<div className='create-form-group'>
						<FormControl fullWidth>
							<textarea
								cols='50'
								rows='10'
								placeholder='Tell your story...'
								className='create-input create-text'
								value={content}
								onChange={(e) => handleChange('content', e.target.value)}
							/>
						</FormControl>
					</div>
					<Button type='submit' variant='contained' className='create-submit'>
						Publish
					</Button>
				</form>
			</Paper>
		</Section>
	);
};

export default CreatePost;
