import { AppBar, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Spacer from '../../components/Spacer';
import './non-user.scss';

const NonUserLayout = ({ children }) => {
	const navigate = useNavigate();

	const handleBlogClick = () => {
		navigate('/');
	};

	const handleLoginClick = () => {
		navigate('/login');
	};

	return (
		<>
			<AppBar
				sx={{
					boxShadow: 'none',
					height: { xs: 56, sm: 64 },
					zIndex: 1101,
					bgcolor: '#f7f7f7',
				}}
			>
				<Toolbar sx={{ position: 'relative', justifyContent: 'center', px: 2 }}>
					<Button label='HOME' btnClass='blog-btn' onClick={handleBlogClick} />
					<Button
						label='Member Login'
						btnClass='lnk-btn'
						onClick={handleLoginClick}
					/>
				</Toolbar>
			</AppBar>
			<Spacer />
			{children}
		</>
	);
};

export default NonUserLayout;
