import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from '@reduxjs/toolkit';
// import { PURGE } from 'redux-persist';
import atsApi from '../../api/atsApi';

export const appAdapter = createEntityAdapter();
const initialState = appAdapter.getInitialState({
	loading: false,
	isMobile: false,
	deleteDialog: false,
	deleteData: null,
	photoOpen: false,
	contentDialog: false,
	errors: null,
});

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setIsMobile: (state, action) => {
			state.isMobile = action.payload;
		},
		openDelete: (state, action) => {
			state.deleteDialog = action.payload;
		},
		setDeleteData: (state, action) => {
			state.deleteData = action.payload;
		},
		setPhotoOpen: (state, action) => {
			state.photoOpen = action.payload;
		},
		setContentDialog: (state, action) => {
			state.contentDialog = action.payload;
		},
	},
});

export const {
	setIsMobile,
	openDelete,
	setDeleteData,
	setPhotoOpen,
	setContentDialog,
} = appSlice.actions;

export default appSlice.reducer;
