import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import './hero.scss';
import Section from '../../../../../../components/Section';

const Hero = () => {
	const { activeUser } = useSelector((state) => state.user);

	return (
		<Section
			id='member-hero'
			maxWidth='lg'
			// containerStyle={{ border: '2px solid green' }}
		>
			<Typography variant='h5' sx={{ mb: 5 }}>
				Hi {activeUser.firstName}, Welcome back 👋
			</Typography>
			<div className='info-box'>
				<h4>Member's Area</h4>
				<p>
					<span>
						Welcome to the ADHD Entrepreneur Hub™, we're so glad you're here!
						The ADHD Entrepreneur Hub™ is your gateway to unlocking clarity and
						confidence on your entrepreneurial journey. Are you ready to dive
						in?
					</span>
				</p>
			</div>
		</Section>
	);
};

export default Hero;
