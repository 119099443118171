import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import './catalogItem.scss';

const CatalogItem = ({ data }) => {
	const { activeUser } = useSelector((state) => state.user);
	const { _id, type, category, title, url, cost, tag, archived } = data;
	const theme = activeUser?.theme;

	// console.log(parseInt(cost));

	return (
		<Card
			sx={{ maxWidth: 345, maxHeight: 330, borderRadius: 4 }}
			className={theme === 'dark' ? theme : ''}
			elevation={10}
		>
			<CardMedia
				sx={{
					height: 195,
					borderRadius: 4,
					borderBottomLeftRadius: 0,
					borderBottomRightRadius: 0,
				}}
				component='iframe'
				src={url}
				title='video content'
				allow='encrypted-media;'
			/>
			<CardContent>
				<Typography variant='h6' component='div'>
					{title}
				</Typography>
				<Typography variant='body2' color='textSecondary'>
					{isNaN(cost) ? cost : `$${cost}`}
				</Typography>
			</CardContent>
			<CardActions>
				<Button>Archive</Button>
				<Button>Edit</Button>
			</CardActions>
		</Card>
	);
};

export default CatalogItem;
