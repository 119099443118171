import {
	Button,
	Checkbox,
	Chip,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	Paper,
	Stack,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	openDelete,
	setDeleteData,
	setPhotoOpen,
} from '../../redux/slices/appSlice';
import {
	setIsCreateCategory,
	setNewCategory,
	createCategory,
} from '../../redux/slices/categorySlice';
import {
	populatePostData,
	clearPostData,
	setTitle,
	setContent,
	setVideoAddress,
	managePostMedia,
	updatePost,
	deletePost,
	clearSuccess,
} from '../../redux/slices/postSlice';
// import { compareArray } from '../../util/helpers';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './post.scss';
import Section from '../../components/Section';
import TransferList from '../../components/TransferList';
import Carousel from '../../components/Carousel';

const Post = () => {
	const { activeUser } = useSelector((state) => state.user);
	const { isCreateCategory, newCategory } = useSelector(
		(state) => state.category
	);
	const {
		selectedPost,
		title,
		categories,
		content,
		mediaPreview,
		videoAddress,
		success,
	} = useSelector((state) => state.post);
	const [updateMode, setUpdateMode] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isMyPost = activeUser?._id === selectedPost?.author?._id ? true : false;

	const handleToggleUpdateMode = () => {
		if (!updateMode) {
			dispatch(populatePostData(selectedPost));
		} else if (updateMode) {
			dispatch(clearPostData());
		}
		setUpdateMode(!updateMode);
	};

	const handleDeleteClick = (type, url) => {
		let data;

		if (type === 'post') {
			data = {
				type,
				action: deletePost(selectedPost?._id),
			};
		} else if (type === 'image') {
			const actionData = new FormData();
			actionData.append('postId', selectedPost?._id);
			actionData.append('url', url);

			data = {
				type,
				action: managePostMedia(actionData),
			};
		}

		dispatch(setDeleteData(data));
		dispatch(openDelete(true));
	};

	const handleChange = (input, value) => {
		const actionMap = {
			title: setTitle,
			isCreateCategory: setIsCreateCategory,
			category: setNewCategory,
			content: setContent,
			video: setVideoAddress,
		};

		const action = actionMap[input];

		action && dispatch(action(value));
	};

	const handleCreateCategory = () => {
		const data = {
			category: newCategory,
		};
		dispatch(createCategory(data));
	};

	const handleAddMedia = () => {
		dispatch(setPhotoOpen(true));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = {
			id: selectedPost?._id,
			...(title !== selectedPost?.title && { title }),
			...(categories !== selectedPost?.categories && { categories }),
			...(content !== selectedPost?.content && { content }),
			...(videoAddress !== selectedPost?.videoAddress && { videoAddress }),
		};

		dispatch(updatePost(data));
	};

	const handleDeleteSuccess = useCallback(() => {
		if (success) {
			if (success === 'Post deleted successfully!') {
				navigate('/admin/blog');
			} else if (success === 'Post updated successfully!') {
				setUpdateMode(false);
			}

			setTimeout(() => {
				clearSuccess();
			}, 2000);
		}
	}, [success, navigate]);

	const handleMediaUpload = useCallback(() => {
		if (mediaPreview) {
			const data = new FormData();
			data.append('postId', selectedPost?._id);
			data.append('b64str', mediaPreview);

			dispatch(managePostMedia(data));
		}
	}, [dispatch, selectedPost, mediaPreview]);

	useEffect(() => {
		handleMediaUpload();
	}, [handleMediaUpload]);

	useEffect(() => {
		handleDeleteSuccess();
	}, [handleDeleteSuccess]);

	// console.log(compareArray(categories, selectedPost?.categories));

	return (
		<Section id='post' maxWidth='lg'>
			<Paper className='wrapper' elevation={5}>
				{isMyPost && (
					<div className='post-actions'>
						<IconButton onClick={handleToggleUpdateMode}>
							<EditIcon htmlColor={updateMode ? 'red' : 'steelblue'} />
						</IconButton>
						<IconButton onClick={() => handleDeleteClick('post')}>
							<DeleteIcon className='delete-icon' />
						</IconButton>
					</div>
				)}
				{updateMode ? (
					<form id='update-form'>
						<Divider>
							<Chip label='Title' size='small' className='divider-chip' />
						</Divider>
						<div className='update-form-group title'>
							<FormControl>
								<input
									type='text'
									placeholder='Title'
									className='update-input'
									value={title}
									onChange={(e) => handleChange('title', e.target.value)}
								/>
							</FormControl>
						</div>
						<Divider>
							<Chip label='Categories' size='small' className='divider-chip' />
						</Divider>
						<div className='update-form-group transfer'>
							<TransferList isUpdate />
							<Stack direction='row' gap={2} alignItems='center'>
								<FormControlLabel
									control={
										<Checkbox
											checked={isCreateCategory}
											onChange={(e) =>
												handleChange('isCreateCategory', e.target.checked)
											}
										/>
									}
									label='New Category'
								/>
								{isCreateCategory && (
									<Stack direction='row' alignItems='center'>
										<input
											type='text'
											placeholder='New Category'
											className='update-input'
											value={newCategory}
											onChange={(e) => handleChange('category', e.target.value)}
										/>
										<IconButton
											disabled={!newCategory}
											className='add-category-btn'
											onClick={handleCreateCategory}
										>
											<AddCircleIcon />
										</IconButton>
									</Stack>
								)}
							</Stack>
						</div>
						<Divider>
							<Chip label='Video' size='small' className='divider-chip' />
						</Divider>
						{videoAddress && (
							<div className='watch-container'>
								<div className='post-video-container'>
									<iframe
										title='Vlog Post'
										src={videoAddress}
										frameBorder='0'
										controls='0'
										allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
										allowFullScreen
									/>
								</div>
							</div>
						)}
						<div className='update-form-group video-url'>
							<FormControl>
								<input
									type='text'
									placeholder='Video URL'
									className='update-input'
									value={videoAddress}
									onChange={(e) => handleChange('video', e.target.value)}
								/>
							</FormControl>
						</div>
						<Divider>
							<Chip label='Images' size='small' className='divider-chip' />
						</Divider>
						<Button onClick={handleAddMedia}>Add Photo</Button>
						{selectedPost?.images?.length > 0 && (
							<div className='post-media-container'>
								{selectedPost?.media?.map((image, i) => (
									<div className='post-img' key={i}>
										<IconButton
											sx={{ position: 'absolute', top: 2, right: 2 }}
											className='del-photo-btn'
											onClick={() => handleDeleteClick('image', image)}
										>
											<DeleteIcon className='delete-icon' fontSize='small' />
										</IconButton>
										<img src={image} alt='' />
									</div>
								))}
							</div>
						)}
						<Divider>
							<Chip label='Content' size='small' className='divider-chip' />
						</Divider>
						<div className='update-form-group'>
							<FormControl fullWidth>
								<textarea
									cols='50'
									rows='10'
									placeholder='Tell your story...'
									className='update-input update-content'
									value={content}
									onChange={(e) => handleChange('content', e.target.value)}
								/>
							</FormControl>
						</div>
						<div className='update-actions'>
							<button
								className='post-undo-btn'
								onClick={handleToggleUpdateMode}
							>
								Undo
							</button>
							<button className='post-update-btn' onClick={handleSubmit}>
								Update
							</button>
						</div>
					</form>
				) : (
					<>
						<div className='post-vitals'>
							<div className='title-categories'>
								<h2>{selectedPost?.title}</h2>
								<Stack direction='row' gap={0.5}>
									{selectedPost?.categories.map((cat) => (
										<h5 key={cat._id}>{cat.category}</h5>
									))}
								</Stack>
							</div>
							<div className='date-author'>
								<span className='post-date'>
									{new Date(selectedPost?.createdAt).toDateString()}
								</span>
								<span className='post-author'>
									{`Author: ${selectedPost?.author?.firstName} ${selectedPost?.author?.lastName}`}
								</span>
							</div>
						</div>
						{selectedPost?.videoAddress && (
							<div className='watch-container'>
								<div className='post-video-container'>
									<iframe
										title='Vlog Post'
										src={selectedPost?.videoAddress}
										frameBorder='0'
										controls='0'
										allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
										allowFullScreen
									/>
								</div>
							</div>
						)}
						{selectedPost?.images?.length > 0 && (
							<div className='carousel-container'>
								<Carousel />
							</div>
						)}
						<div className='post-content-container'>
							<pre className='post-content'>{selectedPost?.content}</pre>
						</div>
					</>
				)}
			</Paper>
		</Section>
	);
};

export default Post;
